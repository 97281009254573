@import url("https://fonts.googleapis.com/css?family=Roboto");

.btn-link {
  background: none;
  padding: 0;
  margin: 0;
}

html {
  font-size: 0.8rem;
}

.vote_box {
  width: 100%;
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 0.7rem 0;
}

.buttonAsLink {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  text-decoration: underline;
  cursor: pointer;
}

.buttonAsLink.whiteText {
  color: white;
}

.buttonAsLink.blueText {
  color: #069;
}

.image_thumb {
  width: 100px;
  padding: 0px;
  margin: 0px;
  white-space: nowrap;
}
.white_text {
  color: white;
}

.numberFormat {
  text-align: right;
}

.prepend-min-width {
  width: 6rem;
}

:root {
  --primary-color: #003699;
  --dark-color: #333333;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: #666;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}

.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.card {
  break-inside: avoid;
  padding: 0rem;
  border: #ccc 1px dotted;
  margin: 0rem 0;
}

.card_adjusted {
  break-inside: avoid;
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 0.7rem 0;
}

.list {
  margin: 0.5rem 0;
}

.list li {
  padding-bottom: 0.3rem;
}

.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}

.grid-2-3 {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 2rem;
}

.badge {
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

input {
  margin: 0rem 0;
}

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

input[type="smalltext"] {
  display: block;
  width: 100px;
}

input[type="date"],
input[type="text"],
input[type="email"],
input[type="password"],
select,
textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 0.8rem;
  border: 1px solid #ccc;
}

input[type="currency"] {
  display: block;
  width: 100px;
  padding: 0.4rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  text-align: right;
}

.grid-input-ccy {
  width: 100px;
  font-family: inherit;
}

.grid-input-acct {
  width: 150px;
}

input[type="submit"],
button {
  font: inherit;
}

.form-group {
  margin: 0.2rem 0;
}

.form-group input {
  margin: 0rem 0;
}

.form-container {
  max-width: 500px;
  margin: 2rem auto;
  overflow: hidden;
  padding: 0 2rem;
}

.form-container h1,
h2,
h3 {
  text-align: center;
}

table th,
table td {
  padding: 1rem;
  text-align: left;
}

table th {
  background: var(--light-color);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
  margin-bottom: 1rem;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--light-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}

@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }

  .grid-2,
  .grid-3,
  .grid-4 {
    grid-template-columns: 1fr;
  }

  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  .navbar {
    display: block;
    text-align: center;
  }

  .navbar h1 {
    margin-bottom: 0.6rem;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }
}
